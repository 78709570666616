import axios from "axios";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  MNSTORE_LOGIN
} from "./loginTypes";

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const loginSuccess = userData => {
  return {
    type: LOGIN_SUCCESS,
    payload: userData
  };
};

const loginFailure = error => {
  return {
    type: LOGIN_FAILURE,
    payload: error
  };
};



export const logout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

export const login = (userName, password) => {
  return dispatch => {
    dispatch(loginRequest());
    var data = "userId="+userName +"&password=" +password ;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios
      .post(MNSTORE_LOGIN, data)
      .then(response => {
        const users = response;
        dispatch(loginSuccess(users));
      })
      .catch(error => {
        const errorMsg = error.response;
        dispatch(loginFailure(errorMsg));
      });
  };
};
