import React, { Component } from "react";
import login from "../redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../css/footer.css";
import "../css/style.css";
import "../css/login.css";
import { SpinnerComponent } from 'react-element-spinner';

const mapStateToProps = state => {
  return {
    loading: state.login.loading,
    loginState: state.login.loginState,
    userData: state.login.userData,
    errorMsg: state.login.errorMsg,
    statusCode: state.login.statusCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(login(username, password))
  };
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
  }

  componentDidMount = () => {
    if(this.props.userData !== "") {
      this.props.history.push(
        {
          pathname:'/dashboard'
        });
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot)=> {
    if(this.props.statusCode === 200){
      this.props.history.push(
        {
          pathname:'/dashboard'
        });
    }
  }

  updateEmail = event => {
    this.setState({
      email: event.target.value
    });
  };

  updatePassword = event => {
    this.setState({
      password: event.target.value
    });
  };

  doLogin = () => {
    this.props.login(this.state.email, this.state.password);
  };

  render() {
    return (
      <div id="login" className="page">
        <SpinnerComponent loading={this.props.loading}  position="global" color="#498DFF" />
        <div className="page-header">
          <span className="logo">
            [<span className="logo-mn">mn</span>]Shop
          </span>

          <span className="page-name-delimiter">-</span>
          <span className="page-name">Log in</span>
        </div>
        <div className="page-body">
          <div id="loginForm" className="page-content">
            <div className="form-name">
              Welcome to {this.state.loading ? "true" : this.state.user}
              <span className="form-name-logo">
                [<span className="form-name-logo-mn">mn</span>]Shop
              </span>
            </div>
            <form>
              <div id="email" className="form-group">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  name="email"
                  onChange={this.updateEmail}
                  placeholder="Enter your email address"
                />
                <div className="invalid-feedback">
                  Please point registered email address
                </div>
              </div>
              <div id="password" className="form-group">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  name="password"
                  onChange={this.updatePassword}
                  placeholder="Enter your password"
                />
                <div className="invalid-feedback">Password is incorrect</div>
              </div>
              <div className="form-row">
                <div className="col">
                  <div id="remember" className="form-group form-check">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" />{" "}
                      Remember me <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
                <Link to="/resetPassword" id="resetPassword" className="text-right"> Forgot password? </Link>
              </div>
              <div>
                <button
                  id="loginBtn"
                  onClick={this.doLogin}
                  type="button"
                  className="btn btn-block btn-login btn-lg"
                >
                  Log in
                </button>
                {
                  this.props.errorMsg !=="" ? <div className="login-error"> <p> {this.props.errorMsg} </p></div>:""
                }

              </div>
            </form>
          </div>
        </div>
        <div className="page-footer text-center">
          <p className="copyrights">
            <span>
              &#169;&nbsp;[<span className="mn-color">mn</span>]Shop
            </span>{" "}
            2020
            <br />
            Developed by&nbsp;[<span className="mn-color">mn</span>]media
            <strong>net</strong>
          </p>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
