import { combineReducers } from "redux";
import loginReducer from "./login/loginReducer";
import resetPasswordReducer from "./resetPassword/resetPasswordReducer";
import resetPassword2Reducer from "./resetPassword2/resetPassword2Reducer";
const rootReducer = combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  resetPassword2: resetPassword2Reducer
});

export default rootReducer;
